import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";

export const DatePicker = ({ value, onChange, timezone }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUIDatePicker
                label="Select a Date"
                value={moment.tz(value, timezone)}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
};
