import moment from "moment";

export function convertToCSV(data) {
    if (data.length === 0) {
        return "";
    }

    const rows = [];
    const headers = [];
    const keys = Object.keys(data[0]);

    keys.forEach((key) => {
        headers.push(
            key
                .replace(/-/g, " ")
                .replace(/\b\w/g, (firstChar) => firstChar.toUpperCase()),
        );
    });
    rows.push(headers);

    for (const row of data) {
        const values = keys.map((header) => {
            if (!row[header]) {
                row[header] = { value: null };
            }
            return row[header].value;
        });
        rows.push(values.join(","));
    }

    return rows.join("\n");
}

export function compareDateHour(x, y) {
    if (x.date.value < y.date.value) {
        return -1;
    } else if (x.date.value > y.date.value) {
        return 1;
    } else {
        if (x.hour.value < y.hour.value) {
            return -1;
        } else if (x.hour.value > y.hour.value) {
            return 1;
        }
    }

    return 0;
}

export function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const createVideoURI = (datetime, device, site, config) => {
    const dateTimeSuffix = moment.utc(datetime).format("YYYY/MM/DD/HH/mm");
    const videoURL = `${config.cdnHost}/${site.name}-${device.name}/data/production/${dateTimeSuffix}.mp4`;

    return videoURL;
};

export const generateMinuteVideoMp4FileNameFromUrl = (
    siteName,
    deviceName,
    url,
    timezone,
) => {
    const regex =
        /https:\/\/[^/]+\/([^/]+)\/data\/production\/(\d{4})\/(\d{2})\/(\d{2})\/(\d{2})\/(\d{2})\.mp4/;
    const match = url.match(regex);

    if (!match) {
        return "hydroai-video-download.mp4";
    }

    const year = match[2];
    const month = match[3];
    const day = match[4];
    const hour = parseInt(match[5], 10);
    const minute = match[6];

    const utcDate = moment.tz(
        `${year}-${month}-${day} ${hour}:${minute}`,
        "YYYY-MM-DD HH:mm",
        "UTC",
    );
    const localDate = utcDate.clone().tz(timezone);

    // Format the local date using moment
    const formattedDate = localDate.format("YYYYMMDDTHHmm");

    return `${siteName}-${deviceName}-${formattedDate}.mp4`;
};
