import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import ReportLayout from "./pages/ReportLayout/ReportLayout";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Login from "./pages/Login/Login";
import PrivateRoutes from "./utils/PrivateRoutes";
import { AppConfig } from "./appConfig";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { theme } from "./styles/Theme.styled";
import { UserProvider } from "./providers/UserProvider";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import Notification from "./components/Error/Notification";

function App({ config }) {
    const [errorState, setErrorState] = useState({
        hasError: false,
        message: "",
    });

    return (
        <AppConfig.Provider value={config}>
            <UserProvider setErrorState={setErrorState}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Navbar />
                    <Toolbar />
                    <Notification
                        hasError={errorState.hasError}
                        message={errorState.message}
                        setState={setErrorState}
                    />
                    <Routes>
                        <Route
                            path="/login"
                            element={<Login setErrorState={setErrorState} />}
                        />
                        <Route element={<PrivateRoutes />}>
                            <Route
                                path="/"
                                element={
                                    <ReportLayout
                                        setErrorState={setErrorState}
                                    />
                                }
                                index
                            />
                            <Route
                                path="/changePassword"
                                element={
                                    <ChangePassword
                                        setErrorState={setErrorState}
                                    />
                                }
                            />
                        </Route>
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </ThemeProvider>
            </UserProvider>
        </AppConfig.Provider>
    );
}

export default App;
