import { Typography, Button, Toolbar, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import moment from "moment-timezone";
import { v1TaglessService } from "../../../services/Services";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { deviceIsValid } from "../../../utils/Validation";
import { useState, useEffect } from "react";
import {
    convertToCSV,
    arrayBufferToBase64,
} from "./utils";
import ImageGrid from "../../../components/Grid/ImageGrid";
import MinuteReport from "./MinuteReport";
import { DailySummaryTable } from "./Table/DailySummaryTable";
import { Direction } from "../../../types/Direction";
import { reduceCumulative } from "./tsutils";

import { useUserContext } from "../../../providers/useUserProvider";

export const EMPTY_DATA_STRING = "-";

const DailyReport = ({
    setErrorState,
    devices,
    activeTab,
    selectedDate,
    setSelectedDate,
}) => {
    const { selectedSite: site, timezone } = useUserContext();
    const [dailyReportData, setDailyReportData] = useState([]);
    const [dailyReportColumns, setDailyReportColumns] = useState([]);
    const [dailySummaryData, setDailySummaryData] = useState([]);

    const [dailySummaryColumns, setDailySummaryColumns] = useState([]);
    const [images, setImages] = useState([]);
    const [isImageLoading, setIsImageLoading] = useState(true);

    const safeStructuredClone = (obj) => {
        if (typeof window.structuredClone === "function") {
            return window.structuredClone(obj);
        }
        return JSON.parse(JSON.stringify(obj));
    };

    const updateTableDate = (newDate) => {
        let clone = safeStructuredClone(dailySummaryColumns);
        clone[new Date(selectedDate).toDateString()] = {
            Header: new Date(newDate).toDateString(),
            columns: [
                {
                    Header: "Hour",
                    accessor: `hour`,
                },
            ],
            ordinal: Number.MIN_SAFE_INTEGER,
        };
        setDailySummaryColumns(clone);
    };

    const generateTimeFrames = () => {
        let hourToTimeFrame = {};
        let start = moment(new Date().setHours(0, 0, 0, 0));
        let end;
        let i = 0;
        while (i < 24) {
            hourToTimeFrame[i] = `${start.format("HH:mm")} `;
            end = start.add(59, "m");
            hourToTimeFrame[i] += `- ${end.format("HH:mm")}`;
            start = end.add(1, "m");
            i++;
        }
        return hourToTimeFrame;
    };

    const apiDataFormatter = (countsArray) => {
        var resObj = {};
        var result = [];
        for (var i = 0; i < countsArray.length; i++) {
            var obj = {
                hour: moment(
                    countsArray[i]["startTimestamp"],
                    "YYYY-MM-DDTHH:mm:ss.SSSZZ",
                )
                    .tz(timezone)
                    .hour(),
                startTime: moment(
                    countsArray[i]["startTimestamp"],
                    "YYYY-MM-DDTHH:mm:ss.SSSZZ",
                )
                    .tz(timezone)
                    .format("hh:mm"),
                endTime: moment(
                    countsArray[i]["endTimestamp"],
                    "YYYY-MM-DDTHH:mm:ss.SSSZZ",
                )
                    .tz(timezone)
                    .format("hh:mm"),
                endTimestamp: moment(
                    countsArray[i]["endTimestamp"],
                    "YYYY-MM-DDTHH:mm:ss.SSSZZ",
                ), // will be comapred against UTC dates. leave as-is.
                upstreamCount: countsArray[i]["upstreamCount"],
                downstreamCount: countsArray[i]["downstreamCount"],
                pipelineId: countsArray[i]["pipelineId"],
            };
            result.push(obj);
            resObj.dataReport = result;
        }
        return resObj;
    };

    const getDeviceImage = async (deviceId, time) => {
        var video = await v1TaglessService.getVideos(deviceId, time);
        if (video) {
            var videoId = video[0].id;
            var buffer = await v1TaglessService.getImage(videoId);
            var binaryImage = arrayBufferToBase64(buffer);
            return binaryImage;
        }
        return null;
    };

    const loadImages = async (startDate) => {
        setIsImageLoading(true);
        let allImages = [];
        await Promise.all(
            devices.map(async (device) => {
                var utcStartTimestamp = moment
                    .utc(startDate)
                    .format("YYYY-MM-DDTHH:mm:ss[Z]");
                const imageBase64 = await getDeviceImage(
                    device.id,
                    utcStartTimestamp,
                );
                allImages.push({
                    image: imageBase64,
                    deviceName: device.displayName,
                    ordinal: parseInt(device.displayName.replace(/[^\d]/g, "")),
                });
                return allImages;
            }),
        );
        setIsImageLoading(false);
        setImages(allImages.sort((a, b) => a.ordinal - b.ordinal));
    };

    const fetchDailyReportTabData = async (startDate, endDate) => {
        let dailyCounts;
        let deviceData;
        let reportPrimaryCols = [
            {
                Header: new Date(selectedDate).toDateString(),
                columns: [
                    {
                        Header: "Hour",
                        accessor: `hour`,
                    },
                ],
                ordinal: Number.MIN_SAFE_INTEGER,
            },
        ];

        const summaryTableData = {};

        let observedHours = new Map();
        let hourToTimeMap = generateTimeFrames();

        const siteDevices = await Promise.all(
            devices.map(async (device) => {
                dailyCounts = await v1TaglessService
                    .getDetections(device.id, {
                        startTimestamp: startDate.toISOString(),
                        endTimestamp: endDate.toISOString(),
                        interval: "1h",
                    })
                    .catch(() => {
                        setErrorState({
                            hasError: true,
                            message: `unable to load detections data for ${device.displayName}`,
                        });
                    });

                if (dailyCounts) {
                    deviceData = {
                        device: device,
                        countData: dailyCounts.counts,
                        validated: !deviceIsValid(
                            device.accurateAsOf,
                            moment(startDate),
                        ),
                    };

                    let primarySummaryCol = {
                        Header: device.displayName,
                        columns: [],
                        ordinal: parseInt(
                            device.displayName.replace(/[^\d]/g, ""),
                        ),
                    };

                    let primaryReportCol = {
                        Header: device.displayName,
                        columns: [],
                        ordinal: parseInt(
                            device.displayName.replace(/[^\d]/g, ""),
                        ),
                    };

                    if (device.type === "CAMERA") {
                        let upstreamCol = {
                            Header: Direction.Upstream,
                            accessor: `${
                                device.name
                            }-${Direction.Upstream.toLowerCase()}`,
                        };

                        let downstreamCol = {
                            Header: Direction.Downstream,
                            accessor: `${
                                device.name
                            }-${Direction.Downstream.toLowerCase()}`,
                        };

                        primarySummaryCol.columns.push(upstreamCol);
                        primarySummaryCol.columns.push(downstreamCol);
                        primarySummaryCol.columns.push({
                            Header: Direction.Net,
                            accessor: `${
                                device.name
                            }-${Direction.Net.toLowerCase()}`,
                        });

                        primaryReportCol.columns.push(upstreamCol);
                        primaryReportCol.columns.push(downstreamCol);

                        const cumulativeData =
                            deviceData.countData &&
                            deviceData.countData.length > 0
                                ? reduceCumulative(deviceData.countData)
                                : {
                                      [Direction.Upstream]: EMPTY_DATA_STRING,
                                      [Direction.Downstream]: EMPTY_DATA_STRING,
                                      [Direction.Net]: EMPTY_DATA_STRING,
                                  };

                        summaryTableData[device.name] = {
                            device: device,
                            directionalCounts: {},
                        };
                        for (let direction in Direction) {
                            summaryTableData[device.name].directionalCounts[
                                direction
                            ] = {
                                generatedOn: startDate,
                                value: cumulativeData[direction],
                            };
                        }
                    }

                    reportPrimaryCols.push(primaryReportCol);
                }
                return deviceData;
            }),
        );

        siteDevices.forEach(async (siteDevice) => {
            if (siteDevice) {
                let formattedData = apiDataFormatter(siteDevice.countData);

                if (formattedData) {
                    let report = formattedData["dataReport"];

                    if (report) {
                        report.forEach((val, _) => {
                            let curr = observedHours.get(parseInt(val.hour));
                            if (!curr) {
                                curr = {
                                    [`hour`]: {
                                        value: hourToTimeMap[
                                            parseInt(val.hour)
                                        ],
                                    },
                                };
                            }

                            const valid = deviceIsValid(
                                siteDevice.device.accurateAsOf,
                                moment(val.endTimestamp),
                            );
                            curr[
                                `${siteDevice.device.displayName}-${Direction.Upstream.toLowerCase()}`
                            ] = {
                                value:
                                    val.upstreamCount !== null
                                        ? val.upstreamCount
                                        : EMPTY_DATA_STRING,
                                shouldGreyOut: !valid,
                            };
                            curr[
                                `${siteDevice.device.displayName}-${Direction.Downstream.toLowerCase()}`
                            ] = {
                                value:
                                    val.downstreamCount !== null
                                        ? val.downstreamCount
                                        : EMPTY_DATA_STRING,
                                shouldGreyOut: !valid,
                            };

                            observedHours.set(parseInt(val.hour), curr);
                        });
                    }
                }
            }
        });

        setDailyReportColumns(
            reportPrimaryCols.sort((a, b) => a.ordinal - b.ordinal),
        );

        setDailySummaryData(summaryTableData);

        const data = Array.from(observedHours.values());
        setDailyReportData(data.sort((a, b) => a.ordinal - b.ordinal));
    };

    useEffect(() => {
        const startDate = selectedDate.clone().tz(timezone).startOf("day");
        const endDate = selectedDate.clone().tz(timezone).endOf("day");

        if (!devices) {
            setErrorState({
                hasError: true,
                message: `unable to load detections data for ${site.displayName}`,
            });
            return;
        }
        fetchDailyReportTabData(startDate.toDate(), endDate.toDate());
        loadImages(startDate);
    }, [selectedDate, devices]);

    return (
        <>
            <DatePicker
                value={selectedDate}
                onChange={(value) => {
                    setSelectedDate(value);
                    updateTableDate(value);
                }}
                timezone={timezone}
            />
            <br />

            <Typography
                variant="h4"
                component="div"
                paddingLeft="16px"
                minHeight="48px"
                sx={{ color: "#043C4A", padding: 1 }}
            >
                Daily Summary
            </Typography>
            <ImageGrid
                data={
                    images.length != 0
                        ? images
                        : devices
                          ? Array.from({ length: devices.length }, (_, i) => ({
                                ordinal: i,
                                image: "",
                            }))
                          : [{ ordinal: 1, image: "" }]
                }
                isLoading={isImageLoading}
            ></ImageGrid>
            <DailySummaryTable data={dailySummaryData} />
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                <Typography
                    sx={{ flex: "1 1 100%", color: "#043c4a" }}
                    variant="h4"
                    id="tableTitle"
                    component="div"
                >
                    Hourly Report
                </Typography>
                <CSVLink
                    data={convertToCSV(dailyReportData)}
                    filename={`${site.name}_hourly_report_${
                        selectedDate.format().split("T")[0]
                    }.csv`}
                    target="_blank"
                    onClick={() => {
                        return !(
                            dailyReportData === undefined ||
                            dailyReportData.length === 0
                        );
                    }}
                >
                    <Tooltip title="Export Daily Hourly Summary (CSV)">
                        <span>
                            <Button
                                sx={{
                                    background: "#4eafb2",
                                    "&:hover": {
                                        backgroundColor: "#043c4a",
                                        boxShadow: "none",
                                    },
                                }}
                                startIcon={<FileDownloadIcon />}
                                disabled={
                                    dailyReportData === undefined ||
                                    dailyReportData.length === 0
                                }
                                variant="contained"
                            >
                                Export
                            </Button>
                        </span>
                    </Tooltip>
                </CSVLink>
            </Toolbar>

            <MinuteReport
                site={site}
                devices={devices}
                selectedDate={selectedDate}
                setErrorState={setErrorState}
                activeTab={activeTab}
            />
        </>
    );
};

export default DailyReport;
