import { IApiClient, RequestConfig } from "./AxiosClient";
import { Site } from "../../types/Site";
import { Device } from "../../types/Device";
import { Detection } from "../../types/Detection";
import { Video } from "../../types/Video";
import { Pipeline } from "../../types/Pipeline";
import { Deployment } from "../../types/Deployment";

interface DataArrayResponse<T> {
    data: T[];
}

/**
 * do not change key-names. They are mapped to the API's expected query params as-is.
 */
interface DetectionParams {
    startTimestamp: string | undefined;
    endTimestamp: string | undefined;
    interval: string | undefined;
}

export interface ITaglessApiClient {
    updateBearerToken(token: string): void;
    getSites(): Promise<Site[] | undefined>;
    getDevicesBySiteID(siteID: string): Promise<Device[] | undefined>;
    getDetections(
        deviceID: number,
        params: DetectionParams,
    ): Promise<Detection[] | undefined>;
    getVideos(deviceID: string, time: string): Promise<Video[] | undefined>;
    getImage(videoID: string): Promise<ArrayBuffer | undefined>;
    getPipelineMetrics(pipelineId: string): Promise<Pipeline | undefined>;
    getDeploymentsBySiteId(siteId: string): Promise<Deployment[] | undefined>;
}

/**
 * A concrete implementation of ITaglessApiClient that provides the logic
 * necessary for executing requests against the Tagless REST API.
 */
export class TaglessApiClient implements ITaglessApiClient {
    apiClient: IApiClient;

    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    updateBearerToken(token: string): void {
        this.apiClient.updateBearerToken(token);
    }

    async getSites(): Promise<Site[] | undefined> {
        const response = await this.apiClient
            .get<DataArrayResponse<Site>>("/sites")
            .catch((error) => {
                throw error;
            });
        return response.data;
    }

    async getDevicesBySiteID(siteID: string): Promise<Device[] | undefined> {
        const response = await this.apiClient.get<DataArrayResponse<Device>>(
            `/sites/${siteID}/devices`,
        );
        return response.data;
    }

    async getDetections(
        deviceID: number,
        params: DetectionParams,
    ): Promise<Detection[] | undefined> {
        let queryString = Object.entries(params)
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        if (queryString.length !== 0) {
            queryString = "?" + queryString;
        }

        const response = await this.apiClient.get<DataArrayResponse<Detection>>(
            `/devices/${deviceID}/counts` + queryString,
        );
        return response.data;
    }

    async getVideos(
        deviceID: string,
        time: string,
    ): Promise<Video[] | undefined> {
        const response = await this.apiClient.get<DataArrayResponse<Video>>(
            `/devices/${deviceID}/videos?time=${time}`,
        );
        return response.data;
    }

    async getImage(videoID: string): Promise<ArrayBuffer | undefined> {
        const requestConfig: any = {
            responseType: "arraybuffer",
            headers: {
                Accept: "image/jpeg",
            },
        };
        const response = await this.apiClient.get<any>(
            `/videos/${videoID}/frames?offset=0`,
            requestConfig,
        );
        return response;
    }

    async getPipelineMetrics(
        pipelineId: string,
    ): Promise<Pipeline | undefined> {
        const response = await this.apiClient.get<Pipeline>(
            `pipelines/${pipelineId}`,
        );
        return response;
    }

    async getDeploymentsBySiteId(
        siteId: string,
    ): Promise<Deployment[] | undefined> {
        const response = await this.apiClient.get<DataArrayResponse<Deployment>>(
            `sites/${siteId}/deployments`,
        );
        return response.data;
    }
}

/**
 * A wrapper around the ITaglessAPIClient interface.
 */
export default class TaglessService {
    taglessApiClient: ITaglessApiClient;

    constructor(client: ITaglessApiClient) {
        this.taglessApiClient = client;
    }

    /**
     * Update's the bearer token of the service's underlying api client.
     * @param token - The auth token to use for future requests.
     */
    updateBearerToken(token: string) {
        this.taglessApiClient.updateBearerToken(token);
    }

    /**
     * Returns the Sites that the user is authorized to view.
     *
     * @returns A promise that can either contain undefined data (in the event of an error)
     *          or an array of Sites that the user is authorized to view.
     */
    async getSites(): Promise<Site[] | undefined> {
        return this.taglessApiClient.getSites().catch((error) => {
            throw error;
        });
    }

    /**
     *
     * @param siteID - the unique ID of the site to look up devices for.
     *
     * @returns A promise that can either contain undefined data (in the event of an error)
     *          or an array of Devices that belong the the site ID.
     */
    async getDevicesBySiteID(siteID: string): Promise<Device[] | undefined> {
        return this.taglessApiClient.getDevicesBySiteID(siteID);
    }

    /**
     *
     * @param deviceID - the unique ID of the device to look up detections for.
     * @param params - optional query parameters that will be treated as filters by the API.
     *
     * @returns A promise that can either contain undefined data (in the event of an error)
     *          or an array of observations for the given deviceID and params.
     */
    async getDetections(
        deviceID: number,
        params: DetectionParams,
    ): Promise<Detection[] | undefined> {
        return this.taglessApiClient.getDetections(deviceID, params);
    }

    /**
     *
     * @param deviceID - the unique ID of the device to look up videos for.
     * @param time - query parameter to filter the API results
     *
     * @returns A promise that can either contain undefined data (in the event of an error)
     *          or an array of observations for the given deviceID and params.
     */
    async getVideos(
        deviceID: string,
        time: string,
    ): Promise<Video[] | undefined> {
        return this.taglessApiClient.getVideos(deviceID, time);
    }

    /**
     *
     * @param videoID - the unique ID of the video to get images for.
     *
     * @returns A promise that can either contain undefined data (in the event of an error)
     *          or an array of observations for the given deviceID and params.
     */
    async getImage(videoID: string): Promise<ArrayBuffer | undefined> {
        return this.taglessApiClient.getImage(videoID);
    }

    /**
     *
     * @param pipelineId - unique ID of the pipeline that processed a video
     *
     * @returns - A promise that can either contain undefined data (in the event of an error)
     *            or a Pipeline object containing the Metrics array.
     */
    async getPipelineMetrics(
        pipelineId: string,
    ): Promise<Pipeline | undefined> {
        return this.taglessApiClient.getPipelineMetrics(pipelineId);
    }

    /**
     * 
     * @param siteId - id of the selected site
     * @returns - A promise that can either contain undefined data (in the event of an error)
     *            or a list of Deployments
     */
    async getDeploymentBySiteId(
        siteId: string,
    ): Promise<Deployment[] | undefined> {
        return this.taglessApiClient.getDeploymentsBySiteId(siteId);
    }
}
