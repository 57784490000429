import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import { UserContext } from "../../providers/UserProvider";
import { DrawerMenu } from "../Drawer/Drawer";
import AccountMenu from "../AccountMenu/AccountMenu";
import { Toolbar } from "@mui/material";
import { useUserContext } from "../../providers/useUserProvider";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop,
})(({ theme, open }) => ({
    display: "flex",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#d8dce3",
}));

const logo = require("../../assets/InnovaseaLogo.png");

export default function Navbar() {
    const { userDetails } = useUserContext();
    return (
        <Box sx={{ display: "flex" }}>
            <AppBar position="fixed" open={true}>
                <Toolbar>
                    <img src={logo} height="50px" />
                    {userDetails ? (
                        <Box sx={{ marginLeft: "auto" }}>
                            <AccountMenu />
                        </Box>
                    ) : null}
                </Toolbar>
            </AppBar>
            {userDetails ? <DrawerMenu /> : null}
        </Box>
    );
}
