import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { Site } from "../types/Site";
import { authService, v1TaglessService } from "../services/Services";

export interface IUserDetails {
    username: string | null;
}

export interface UserContextType {
    userDetails: IUserDetails | null;
    setUserDetails: (userDetails: IUserDetails | null) => void;
    allowedSites: Site[];
    selectedSite: Site | null;
    setSelectedSite: (site: Site | null) => void;
    timezone: string;
}

interface Props {
    setErrorState(error: any): void;
}

const UserContext = createContext<UserContextType | null>(null);

const UserProvider: React.FC<PropsWithChildren<Props>> = ({
    children,
    setErrorState,
}) => {
    const [userDetails, setUserDetails] = useState<IUserDetails | null>(
        authService.getIdentityToken()
            ? { username: authService.getUsername() }
            : null,
    );
    const [allowedSites, setAllowedSites] = useState<Site[]>([]);
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);

    useEffect(() => {
        const loadAuthorizedSites = async () => {
            try {
                const token = authService.getIdentityToken();
                if (!token) {
                    throw new Error("No auth token available");
                }

                const sites = await v1TaglessService.getSites();
                if (!sites || sites.length === 0) {
                    throw new Error("No sites available");
                }

                setAllowedSites(sites);

                // Handle site selection
                const savedSiteName = localStorage.getItem("selected_site");
                let siteToSelect: Site | null = null;

                if (savedSiteName) {
                    // Try to find the saved site
                    siteToSelect =
                        sites.find(
                            (site) => site.displayName === savedSiteName,
                        ) || null;
                }

                // If no saved site or saved site not found, use the first site
                if (!siteToSelect && sites.length > 0) {
                    siteToSelect = sites[0];
                    localStorage.setItem(
                        "selected_site",
                        siteToSelect.displayName,
                    );
                }

                setSelectedSite(siteToSelect);
            } catch (error) {
                setErrorState({
                    hasError: true,
                    message: "unable to load Sites",
                });
                setAllowedSites([]);
                setSelectedSite(null);
            }
        };

        if (userDetails && userDetails != null) {
            loadAuthorizedSites();
        } else {
            setAllowedSites([]);
            setSelectedSite(null);
        }
    }, [userDetails, setErrorState]);

    useEffect(() => {
        if (selectedSite) {
            localStorage.setItem("selected_site", selectedSite.displayName);
        }
    }, [selectedSite]);

    return (
        <UserContext.Provider
            value={{
                userDetails,
                setUserDetails,
                allowedSites,
                selectedSite,
                setSelectedSite,
                timezone: "America/Halifax",
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };
