import { BoundedData } from "../../../types/BoundedData";
import { Direction } from "../../../types/Direction";
import _ from "lodash";
import { BoundedCount, CountType, cumulativeSum } from "../../../types/Count";
import moment from "moment";

type Count = {
    [key in Direction]: number | null;
};

// temporary interface until a TS refactor is done
// and a unified count interface is defined for reuse.
interface InputCount {
    upstreamCount: number | null;
    downstreamCount: number | null;
}

/** A list of counts to reduce down to a single, cumulative Count. */
export const reduceCumulative = (counts: InputCount[]): Count => {
    return counts.reduce(
        (prev, curr) => {
            return {
                [Direction.Upstream]: prev.Upstream + (curr.upstreamCount ?? 0),
                [Direction.Downstream]: prev.Downstream + curr.downstreamCount!,
                [Direction.Net]:
                    prev.Net +
                    (curr.upstreamCount ?? 0) -
                    (curr.downstreamCount ?? 0),
            };
        },
        {
            [Direction.Upstream]: 0,
            [Direction.Downstream]: 0,
            [Direction.Net]: 0,
        },
    );
};

export const getCSVDataFromBoundedData = (
    boundedData: BoundedData,
    showCI: boolean,
): string => {
    const headers = ["Date"];
    const devices = Object.values(boundedData).sort((a, b) =>
        a.device.displayName.localeCompare(b.device.displayName),
    );

    let headersPerDevice: number;

    devices.forEach((device) => {
        let deviceHeaders = ["upstream", "downstream", "net", "net cumulative"];
        headersPerDevice = deviceHeaders.length;

        if (showCI) {
            deviceHeaders = [
                "upstream lower",
                "upstream predicted",
                "upstream upper",
                "downstream lower",
                "downstream predicted",
                "downstream upper",
                "net lower",
                "net predicted",
                "net upper",
                "net cumulative lower",
                "net cumulative predicted",
                "net cumulative upper",
            ];
            headersPerDevice = deviceHeaders.length;
        }

        deviceHeaders.forEach((header) =>
            headers.push(`${device.device.displayName} ${header}`),
        );
    });

    if (devices.length === 0) {
        return headers.join(",");
    }

    // bundle cumulatives and instants into a multi-dimensional
    // array which will be easier to use with lodash.
    const deviceCounts = devices.map((device) => ({
        cumulatives: device.cumulative,
        instants: device.instant,
    }));

    // create a map of cumulatives keyed by the date.
    // we can always use the last cumulative of the day
    // for reporting purposes.
    const cumulativeMap = deviceCounts.map((device) => {
        return _.reduce(
            device.cumulatives,
            (acc, item) => {
                const day = item.timestamp.toLocaleDateString();
                acc[day] = item;
                return acc;
            },
            {} as { [date: string]: BoundedCount },
        );
    });

    // reduce each day for each device down to a singular count and cumulative.
    // Only include non-imputed instant measurements.
    // If the day had no non-imputed instants, then it is a total outage.
    const deviceDailyCounts = _(deviceCounts)
        .map((device, index) => {
            const instantsByDay = _.groupBy(device.instants, (instant) =>
                instant.timestamp.toLocaleDateString(),
            );
            return _.map(instantsByDay, (instants, day) => {
                const observedInstants = instants.filter((count) => !count.imputed)
                return {
                    timestamp: day,
                    instant: cumulativeSum(instants).at(-1)!,
                    cumulative: cumulativeMap[index][day],
                    totalOutage: observedInstants.length === 0
                }
            });
        })
        .value();

    // zip the daily counts so that the multi-dimensional array has
    // the shape [ [day-1 device 1 counts, day-2 device 2 counts, ...], 
    // [day-2 device 1 counts, day-2 device 2 counts, ...], ... ]
    // as this defines the shape of the CSV.
    const dailyCounts = _.reduce(
        _.zip(...deviceDailyCounts),
        (acc, dailyDevicesCounts) => {
            if (dailyDevicesCounts && dailyDevicesCounts[0]) {
                const day = dailyDevicesCounts[0].timestamp;

                if (!acc[day]) {
                    acc[day] = [];
                }

                dailyDevicesCounts.forEach((valuess, index) => {
                    if (!acc[day][index]) {
                        acc[day][index] = {
                            instant: valuess!.instant,
                            cumulative: valuess!.cumulative,
                            totalOutage: valuess!.totalOutage,
                        };
                    }
                });
            }
            return acc;
        },
        {} as {
            [timestamp: string]: {
                instant: BoundedCount;
                cumulative: BoundedCount;
                totalOutage: boolean;
            }[];
        },
    );

    // iterate through the dailyCounts, creating a row for each 
    // entry in dailyCounts. Any records with a total outage
    // will have their values replaced with empty strings.
    const dataRows = Object.entries(dailyCounts).map(([day, counts]) => {
        const row: string[] = [moment(day).format("MM/DD/YYYY")];

        counts.forEach((count) => {
            if (!count || count.totalOutage) {
                row.push(...new Array(headersPerDevice).fill(""));
            } else {
                if (showCI) {
                    row.push(
                        count.instant.value!.Upstream[
                            "Lower Bound"
                        ]!.toString(),
                        count.instant.value!.Upstream!.Predicted!.toString(),
                        count.instant.value!.Upstream![
                            "Upper Bound"
                        ]!.toString(),
                        count.instant.value!.Downstream![
                            "Lower Bound"
                        ]!.toString(),
                        count.instant.value!.Downstream!.Predicted!.toString(),
                        count.instant.value!.Downstream![
                            "Upper Bound"
                        ]!.toString(),
                        count.instant.value!.Net!["Lower Bound"]!.toString(),
                        count.instant.value!.Net!.Predicted!.toString(),
                        count.instant.value!.Net!["Upper Bound"]!.toString(),

                        count.cumulative.value!.Net![
                            CountType.LowerBound
                        ].toString(),
                        count.cumulative.value!.Net!.Predicted.toString(),
                        count.cumulative.value!.Net![
                            CountType.UpperBound
                        ].toString(),
                    );
                } else {
                    row.push(
                        count.instant.value!.Upstream!.Predicted!.toString(),
                        count.instant.value!.Downstream!.Predicted!.toString(),
                        count.instant.value!.Net!.Predicted!.toString(),

                        count.cumulative.value!.Net!.Predicted.toString(),
                    );
                }
            }
        });
        return row;
    });
    return [headers, ...dataRows].map((row) => row.join(",")).join("\n");
};
