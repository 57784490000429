import * as React from "react";
import "./Report.css";
import { Box, Container, Tab, Tabs } from "@mui/material";
import {
    MemoizedTabPanel,
    a11yProps,
} from "../../../components/TabPanel/TabPanel";
import { useState } from "react";
import DailyReport from "./DailyReport";
import SummaryReport from "./SummaryReport";
import { ConfidenceIntervalSwitch } from "./Switches/ConfidenceIntervalSwitch";

import { useSiteDevices } from "../../../hooks/useSiteDevices";
import { useUserContext } from "../../../providers/useUserProvider";
import moment from "moment";

const CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY = "confidence_interval_status";

const Report = ({ setErrorState }) => {
    const { selectedSite, timezone } = useUserContext();
    const { devices, isLoading } = useSiteDevices(setErrorState);
    const [activeTab, setActiveTab] = useState(0);
    const [ciEnabled, setCIEnabled] = useState(
        localStorage.getItem(CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY) === "true",
    );

    const [selectedDate, setSelectedDate] = useState(moment().tz(timezone));

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const toggleConfidenceIntervals = () => {
        const newValue = !ciEnabled;
        setCIEnabled(newValue);
        localStorage.setItem(
            CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY,
            String(newValue),
        );
    };

    if (!selectedSite) return null;
    if (isLoading) return <div>Loading devices...</div>;

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="report tabs"
                    >
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Daily Report" {...a11yProps(1)} />
                    </Tabs>
                    {activeTab === 0 && (
                        <ConfidenceIntervalSwitch
                            checked={ciEnabled}
                            onChange={toggleConfidenceIntervals}
                        />
                    )}
                </Container>
            </Box>

            <MemoizedTabPanel value={activeTab} index={0}>
                <SummaryReport
                    devices={devices}
                    setErrorState={setErrorState}
                    enableCI={ciEnabled}
                />
            </MemoizedTabPanel>
            <MemoizedTabPanel value={activeTab} index={1}>
                <DailyReport
                    devices={devices}
                    setErrorState={setErrorState}
                    activeTab={activeTab}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
            </MemoizedTabPanel>
        </Box>
    );
};

export default Report;
