import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeAuthService, initializeV1TaglessService } from './services/Services';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

fetch("/config.json")
  .then(r => r.json())
  .then(config => {
    initializeV1TaglessService({
      baseUrl: config.apiHost,
      version: "v1"
    })
    initializeAuthService(config.cognitoUserPoolId, config.cognitoClientId)
    return config
  })
  .then(config => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App config={config}/>
          </QueryClientProvider>
        </BrowserRouter>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
