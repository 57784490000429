const PasswordRequirementsError = () => {
    return (
        <>
            <span>Password should have at least:</span>
            <ul>
                <li>Minimum 8 characters</li>
                <li>1 special character</li>
                <li>1 uppercase</li>
                <li>1 lowercase</li>
            </ul>
        </>
    );
};

export default PasswordRequirementsError;
