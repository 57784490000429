import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import React from "react";
import Report from "./Components/Report";
import { useUserContext } from "../../providers/useUserProvider";

interface Error {
    hasError: boolean;
    message: string;
}

interface Props {
    setErrorState(error: Error): void;
}

const ReportLayout: React.FC<Props> = ({ setErrorState }: Props) => {
    const { selectedSite } = useUserContext();

    return (
        <Container sx={{ ml: "20%" }}>
            {selectedSite ? (
                <>
                    <Typography
                        className="SiteHeader"
                        variant="h3"
                        sx={{
                            color: "#043C4A",
                            paddingTop: 5,
                            paddingBottom: 3,
                        }}
                        component="div"
                    >
                        {selectedSite.displayName}
                    </Typography>
                    <Report setErrorState={setErrorState} />
                </>
            ) : null}
        </Container>
    );
};

export default ReportLayout;
