import _ from "lodash";

/**
 * findMissingDates accepts an array of Date types and returns an array whose elements
 * are gaps of the specified interval in the original array with respect to the start
 * (or first element is dates[0] < start).
 * @param dates a sorted array of Date types.
 * @param start the first Date expected to appear in the output array (will not filter).
 * @param end the last Date expected to appear in the array (will not filter).
 * @param interval the ms interval from start for which a new Date should be present.
 * If dates[i] + interval is not dates[i+1], then dates[i] + interval will be appended.
 * @returns an array of dates with missing dates appended to the array.
 */
export function findMissingDates(
    dates: Date[],
    start: Date,
    end: Date,
    interval: number,
): Date[] {
    const missing: Date[] = []
    if (dates.length > 0) {
        if (_.first(dates)!.valueOf() > start.valueOf()) {
            missing.push(start)
            dates = _.concat([start], dates);
        }

        if (
            _.last(dates)!.valueOf() < end.valueOf() &&
            start.valueOf() != end.valueOf()
        ) {
            missing.push(end)
            dates = _.concat(dates, end);
        }
    }
    return missing.concat(dates.reduce(
        ((hash) => {
            return function (acc: Date[], curr: Date) {
                const numMissingHours =
                    (Date.parse(curr.toISOString()) - hash.prev) / interval;
                if (hash.prev && numMissingHours > 1) {
                    for (let i = 1; i < numMissingHours; i++) {
                        acc.push(new Date(hash.prev.valueOf() + i * interval));
                    }
                }
                hash.prev = new Date(curr);
                return acc;
            };
        })(Object.create(null)),
        [],
    ));
}